
<template>
    <div class="list">
		
			
		
		<el-row :gutter="20">
			<el-col :span="2">
				<div class="grid-content bg-purple">
					<el-row style="margin-bottom: 10px;">
						<el-button size="mini" plain @click="forArr(data,true)">全部展开</el-button>
					</el-row>
					 <el-row>
						<el-button size="mini" plain @click="forArr(data,false)">全部折叠</el-button>
					</el-row>
					<el-row>
						<el-button size="mini" type="primary"  icon="el-icon-plus" @click="add(0)">添加</el-button>
					</el-row>
				</div>
			</el-col>
			<el-col :span="22">
				<div class="grid-content bg-purple-light">
					<div class="search">
						<el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
							<el-form-item label="菜单名称" prop="title">
								<el-input v-model="formInline.title" placeholder="菜单名称"></el-input>
							</el-form-item>
							<el-form-item label="状态" prop="status">
								<el-select v-model="formInline.status" placeholder="状态">
								<el-option label="开启" value="1"></el-option>
								<el-option label="关闭" value="0"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="类型" prop="is_menu">
								<el-select v-model="formInline.is_menu" placeholder="类型">
								<el-option label="菜单" value="1"></el-option>
								<el-option label="按钮" value="0"></el-option>
								</el-select>
							</el-form-item>
							
							<el-form-item>
								<el-button type="primary" @click="getList()">查询</el-button>
								<el-button @click="reset('formInline')">重置</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-table  v-loading="loading"  :data="data" style="width: 100%" row-key="id" border lazy :load="load" :tree-props="{children: 'children', hasChildren: 'hasChildren'}"> >
			<!-- <el-table-column type="selection" width="55"></el-table-column> -->
			<!-- <el-table-column align="center" prop="id" label="序号" sortable width="80"  type=""></el-table-column> -->
			<el-table-column align="center" prop="title" label="菜单名称" width="260"></el-table-column>
			<el-table-column align="center" label="图标"  width="100"  type="">
 				<template slot-scope="scope" >
					<i :class="scope.row.icon"></i>
					<span style="margin-left: 10px"></span>
				</template>

			</el-table-column>
			<el-table-column align="center" prop="href" label="路由"  width="260"></el-table-column>
			<el-table-column align="center" width="100"  label="状态">
				<template slot-scope="scope" >
					<el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" @change="changeSwitch(scope.row)"></el-switch>
				</template>
			</el-table-column>
			<el-table-column align="center" width="100"  label="类型">
				<template slot-scope="scope" >
					<el-tag v-if="scope.row.is_menu==1" size="medium">菜单</el-tag>				
					<el-tag v-else-if="scope.row.is_menu==0" size="info">按钮</el-tag>
					<el-tag v-else size="medium">菜单</el-tag>
				</template>
			</el-table-column>
			<el-table-column align="center" sortable width="80" prop="sort" label="排序"></el-table-column>
			<el-table-column align="center" prop="created_at" label="创建时间"></el-table-column>
			<el-table-column align="center" fixed="right" label="操作" width="260">
      			<template slot-scope="scope">
					<el-button  @click="modify(scope.row)" type="text" size="small"><i class="el-icon-edit"></i>编辑</el-button>
					&nbsp;
					<el-popconfirm title="确定要删除这条数据吗" @confirm="deletes(scope.row.id,data)">
						<el-button class="text-danger"  slot="reference"  type="text" size="small"><i class="el-icon-delete"></i>删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<Modal v-model="router" :footer-hide="true" class-name="order-modal" @on-cancel="ModalCancel"  :mask="false" :title="modelTitle">
		       <router-view  @routerCancel="ModalCancel"></router-view>
		</Modal>
	</div>
	
</template>
<script>
	import {request} from "@/library/request.js";

    export default {
        data () {
            return {
				data:[],
				loading: false,
				router:false,
				modelTitle:"",
				formInline:{
					title:"",
					status:"",
					is_menu:null
				}
			}
        },
		methods: {

			
			// forArr(arr,isExpand) {
			// 	arr.forEach(i => {	
			// 		this.$refs.treeTable.toggleRowExpansion(i, isExpand);
			// 		if (i.children) {
			// 			this.forArr(i.children, isExpand);
			// 		}
			// 	});
			// },

			getList(){
				this.$Loading.start();
				this.loading = true
				let search = {
					type:2,
					searchParams:{
						'title-%%':this.formInline.title,
						'status-=':this.formInline.status,
						'is_menu-=':this.formInline.is_menu,
					}
				}
				return request({
					url:'system/menu/list',
					method:"get",
					params:search
				}).then(response  => {
					this.$Loading.finish();
					this.loading = false
					this.data = response.data
				}).then(err => {
					if(err){
						this.loading = false
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})
			},
			reset(formName){
				this.$refs[formName].resetFields();
				this.getList()
			},
			load(tree, treeNode, resolve) {
				return request({
					url:'system/menu/list',
					method:"get",
					params:{type:3,searchParams:{"pid-=":tree.id}}
				}).then(response  => {
					resolve(response.data)
				}).then(err => {
					if(err){
						this.$Message.error("系统异常，请稍后重试")
					}
				})
				this.getList()
        },
			add(){
				this.modelTitle ="创建菜单"
				this.router = true
				this.$router.push({
					path:'/system/menu/create'
				})
			},
      deletes(id,data){
        return request({
          url:'system/menu/delete/'+id,
          method:"post",
        }).then(response  => {
          this.getList()
        }).then(err => {
          if(err){
            this.loading = false
            this.$Loading.error();
            this.$Message.error("系统异常，请稍后重试")
          }
        })
      },
			modify(row){
				this.modelTitle ="编辑菜单"
				this.router = true
				this.$router.push({
					name:'menu/edit',
					params:{id:row.id}
				})
			},
			ModalCancel(res){
				this.router=false
				this.$router.push({
					path:'/system/menu'
				})
				if(res){
					this.getList()
				}
			},
			
		},
		mounted() {
			var route =this.$route.path;
			if(route!="/system/menu"){
				this.ModalCancel()
			}
			this.getList()
		}
    }
</script>
<style scoped>
	@import url('/css/app.css');
</style>